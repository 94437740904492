import { useFeeAgreementTemplateList } from "@gocardless/api/dashboard/fee-agreement-template";
import {
  DetailsScheme,
  FeeAgreementTemplateResource,
  PricingType,
} from "@gocardless/api/dashboard/types";

import { retrieveAddOnPricing } from "../v3/retrieveAddOnPricing/retrieveAddOnPricing";
import { AddOnPricing } from "../v3/retrieveAddOnPricing/addOnPricingGeoData";

import { schemeToIsDebitScheme } from "src/common/scheme";
import { CountryCodeSupported } from "src/common/country";

export const isPricingType =
  (pricingType: PricingType) => (template: FeeAgreementTemplateResource) =>
    template.pricing_type === pricingType;

export const isDebitScheme = () => (template: FeeAgreementTemplateResource) =>
  template.scheme && schemeToIsDebitScheme[template.scheme];

export const hasMonthlyFees = () => (template: FeeAgreementTemplateResource) =>
  template.plus_monthly_fee !== null && template.pro_monthly_fee !== null;

export const isScheme =
  (scheme: DetailsScheme) => (template: FeeAgreementTemplateResource) =>
    template.scheme === scheme;

const schemeGroups: Record<DetailsScheme, DetailsScheme | null> = {
  [DetailsScheme.Ach]: null,
  [DetailsScheme.Autogiro]: null,
  [DetailsScheme.Bacs]: DetailsScheme.SepaCore,
  [DetailsScheme.Becs]: DetailsScheme.BecsNz,
  [DetailsScheme.BecsNz]: DetailsScheme.Becs,
  [DetailsScheme.Betalingsservice]: null,
  [DetailsScheme.Pad]: null,
  [DetailsScheme.SepaCore]: null,
  [DetailsScheme.SepaCor1]: null,
  [DetailsScheme.PayTo]: null,
  [DetailsScheme.FasterPayments]: null,
};

export function useFeeAgreementTemplates(geo?: string): {
  domestic: FeeAgreementTemplateResource | null;
  domesticSchemeGroup: FeeAgreementTemplateResource | null;
  international: FeeAgreementTemplateResource | null;
  defaultFeeAgreement: FeeAgreementTemplateResource | null;
  addOns: AddOnPricing | null;
} {
  const { data } = useFeeAgreementTemplateList({ geo });

  const feeAgreementTemplates = data?.fee_agreement_templates ?? [];

  // find the first fee agreement template with `pricing_type: domestic`
  // that is a direct debit scheme in config (scheme.ts) to display
  // home scheme direct debit transaction fees (and not IBP scheme fees)
  const domestic =
    feeAgreementTemplates
      .filter(isPricingType(PricingType.Domestic))
      .find(isDebitScheme()) ?? null;

  // if the domestic scheme is part of a group in the `schemeGroups` record
  // above, find a fee agreement template for the other scheme in the group
  // to display its fees e.g. fees for eurozone using sepa_core when you are
  // in GB
  const domesticSchemeGroup = (() => {
    if (!domestic?.scheme) return null;
    if (!schemeGroups[domestic.scheme]) return null;

    const scheme = schemeGroups[domestic.scheme] as DetailsScheme;

    return (
      feeAgreementTemplates
        .filter(isPricingType(PricingType.SchemeGroup))
        .find(isScheme(scheme)) ?? null
    );
  })();

  // find the first fee agreement template with `pricing_type: international`
  // that has monthly fees, falling back to sepa_core or bacs if there is none
  // to display as worldwide fees
  const internationalTemplates = feeAgreementTemplates.filter(
    isPricingType(PricingType.International)
  );
  const international =
    internationalTemplates.find(hasMonthlyFees()) ??
    internationalTemplates.find(isScheme(DetailsScheme.SepaCore)) ??
    internationalTemplates.find(isScheme(DetailsScheme.Bacs)) ??
    null;

  const addOns = geo ? retrieveAddOnPricing(geo as CountryCodeSupported) : null;

  return {
    domestic,
    domesticSchemeGroup,
    international,
    defaultFeeAgreement: domestic || international,
    addOns,
  };
}
