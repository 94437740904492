import { I18n } from "@lingui/core";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { ReactElement } from "react";

import { CountryCodes } from "src/common/country";
import { countryToCurrency, Currency } from "src/common/currencies";
import { ToTranslate } from "src/components/i18n";
import { useFeeAgreementTemplates } from "src/components/routes/Setup/package-selection/useFeeAgreementTemplates/useFeeAgreementTemplates";
import { usePrimaryCreditor } from "src/libraries/creditor/hooks";
import { useOrganisation } from "src/libraries/organisation/hooks";

export const currencyToCapAmount: Record<Currency, number> = {
  [Currency.Aud]: 10000,
  [Currency.Cad]: 10000,
  [Currency.Dkk]: 50000,
  [Currency.Eur]: 5000,
  [Currency.Gbp]: 5000,
  [Currency.Nzd]: 10000,
  [Currency.Sek]: 70000,
  [Currency.Usd]: 10000,
};

export function renderCapAmount(i18n: I18n, geo: CountryCodes): string {
  const currency = countryToCurrency(geo);
  const capAmount = i18n.number(currencyToCapAmount[currency], {
    style: "currency",
    currency,
    minimumFractionDigits: 0,
    currencyDisplay: "narrowSymbol",
  });

  return capAmount;
}

export enum FeesTextVariant {
  Intro = "INTRO",
  Setup = "SETUP",
}

export function useFeesText(variant: FeesTextVariant) {
  const { i18n } = useLingui();
  const creditor = usePrimaryCreditor();
  const { domestic } = useFeeAgreementTemplates(creditor?.geo ?? undefined);
  const organisation = useOrganisation();

  if (!creditor?.geo) return null;

  const currency = countryToCurrency(creditor.geo as CountryCodes);

  const formatPercent = (amount: number) =>
    i18n.number(amount / 100, {
      style: "percent",
      minimumFractionDigits: 1,
    });

  const formatCurrency = (amount: number) =>
    i18n.number(amount / 100, {
      style: "currency",
      currency,
      minimumFractionDigits: (amount / 100) % 1 ? 2 : 0,
      currencyDisplay: "narrowSymbol",
    });

  if (!domestic) return null;

  const percentageValue = organisation?.is_on_pricing_v3
    ? domestic.variable_transaction_fee_percentage_standard_v3
    : domestic.percentage;
  const maximumValue = organisation?.is_on_pricing_v3
    ? domestic.maximum_transaction_fee_standard_v3
    : domestic.maximum;

  const percentage = formatPercent(parseFloat(percentageValue as string));
  const flatFee = formatCurrency(domestic.flat_fee as number);
  const maximum = formatCurrency(maximumValue as number);

  if (!domestic.high_value_threshold) {
    const feesTextVariants: Record<FeesTextVariant, ReactElement> = {
      [FeesTextVariant.Intro]: (
        <ToTranslate>
          We charge {percentage} + {flatFee} per domestic transaction capped at{" "}
          {maximum}.
        </ToTranslate>
      ),
      [FeesTextVariant.Setup]: (
        <Trans id="setup.activation-discount.transaction-fees-text.v3.1">
          On our Standard plan, we charge {percentage} + {flatFee} per domestic
          transaction capped at {maximum}.
        </Trans>
      ),
    };

    return feesTextVariants[variant];
  }

  const highValuePercentage = formatPercent(
    parseFloat(domestic.high_value_percentage as string)
  );
  const highValueThreshold = formatCurrency(
    domestic.high_value_threshold as number
  );

  const feesTextVariants: Record<FeesTextVariant, ReactElement> = {
    [FeesTextVariant.Intro]: (
      <>
        <ToTranslate>
          We charge {percentage} + {flatFee} per domestic transaction capped at{" "}
          {maximum}.
        </ToTranslate>{" "}
        <ToTranslate>
          An additional fee of {highValuePercentage} applies to payments above{" "}
          {highValueThreshold}.
        </ToTranslate>
      </>
    ),
    [FeesTextVariant.Setup]: (
      <>
        <Trans id="setup.activation-discount.transaction-fees-text.v3.1">
          On our Standard plan, we charge {percentage} + {flatFee} per domestic
          transaction capped at {maximum}.
        </Trans>{" "}
        <Trans id="setup.activation-discount.transaction-fees-text.v3.2">
          An additional fee of {highValuePercentage} applies to payments above{" "}
          {highValueThreshold}.
        </Trans>
      </>
    ),
  };

  return feesTextVariants[variant];
}
