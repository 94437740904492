import {
  Button,
  ButtonGroup,
  ButtonSize,
  ButtonVariant,
  ColorPreset,
  Dialog,
  P,
  TypePreset,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";

import { ToTranslate } from "src/components/i18n";
import {
  FeesTextVariant,
  useFeesText,
} from "src/components/routes/Setup/common/helpers/activationDiscount";

interface StartStepModalProps {
  open: boolean;
  onClose: () => void;
}
export const RewardsCostModal: React.FC<StartStepModalProps> = ({
  open,
  onClose,
}) => {
  const feesText = useFeesText(FeesTextVariant.Intro);

  return (
    <Dialog
      as="div"
      open={open}
      header={
        <P preset={TypePreset.Heading_03}>
          <ToTranslate id="intro.start.how-much-will-it-cost">
            How much will it cost me afterwards?
          </ToTranslate>
        </P>
      }
      footer={
        <ButtonGroup arrangement="row-end">
          <Button
            variant={ButtonVariant.PrimaryOnLight}
            onClick={onClose}
            size={ButtonSize.Sm}
          >
            <Trans id="Go back">Go back</Trans>
          </Button>
        </ButtonGroup>
      }
      closeAction={{
        label: <Trans id="Close">Close</Trans>,
        onClose,
      }}
    >
      <P color={ColorPreset.TextOnLight_02}>
        <ToTranslate>
          After your discount period you&apos;ll still benefit from low
          pay-as-you-go transaction fees and no monthly costs on our Standard
          plan.
        </ToTranslate>
      </P>
      <P spaceAbove={1} color={ColorPreset.TextOnLight_02}>
        {feesText}
      </P>
      <P spaceAbove={1} color={ColorPreset.TextOnLight_02}>
        <ToTranslate>
          GoCardless is 54% cheaper than average card payment fees.
        </ToTranslate>
      </P>
    </Dialog>
  );
};
