import {
  Box,
  Button,
  ButtonLayout,
  ButtonSize,
  ButtonVariant,
  Dialog,
  FontWeight,
  H3,
  JustifyContent,
  PlainLink,
  Text,
  TypePreset,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";

import { useCollectionsPermitted } from "../routes/SetupPayments/common/hooks/useCollectionsPermitted";

import { getRouteURL, Route, routerPush } from "src/common/routing";
import ExtendDiscountBox from "src/components/incentive-banner/ExtendDiscountBox";
import { RewardsModal } from "src/components/rewards-modal/RewardsModal";
import { useOptimizelyVariation } from "src/technical-integrations/optimizely/useOptimizelyVariation";
import { OptimizelyFlag } from "src/technical-integrations/optimizely/constants";

interface ExtendDiscountDialogProps {
  open: boolean;
  toggleDialog: () => void;
}
const ExtendDiscountDialog: React.FC<ExtendDiscountDialogProps> = ({
  open,
  toggleDialog,
}) => {
  const { collectionsEnabled } = useCollectionsPermitted();
  const { isVariationOn } = useOptimizelyVariation({
    flag: OptimizelyFlag.TURBO_GROWTH_REWARDS_AND_BOOSTS,
  });

  if (isVariationOn) {
    return <RewardsModal open={open} onClose={toggleDialog} />;
  }

  const paymentPageLink = (
    <PlainLink
      href={getRouteURL({ route: Route.Payments, absolute: true })}
      decoration="underline"
    >
      <Trans id="get-your-first-payout.payment-page-link">payments page</Trans>
    </PlainLink>
  );
  return (
    <Dialog
      as="div"
      open={open}
      header={
        <H3 preset={TypePreset.Heading_03} weight={FontWeight.SemiBold}>
          <Trans id="extend-discount.dialog.title">
            Extend your no fee period
          </Trans>
        </H3>
      }
      footer={
        <Box layout="flex" justifyContent={JustifyContent.End}>
          <Button
            variant={ButtonVariant.PrimaryOnLight}
            size={ButtonSize.Sm}
            layout={[ButtonLayout.Full, ButtonLayout.Inline]}
            onClick={() => routerPush({ route: Route.SetupPayments })}
          >
            <Trans id="get-your-first-payout.create-payment-link">
              Create payment
            </Trans>
          </Button>
        </Box>
      }
      closeAction={{ label: "Close", onClose: toggleDialog }}
    >
      <ExtendDiscountBox collectionsEnabled={collectionsEnabled} />

      <Box spaceAbove={2}>
        <Text preset={TypePreset.Body_02}>
          <Trans id="extend-discount.dialog.description">
            Create a payment to get started or view scheduled payments on the{" "}
            {paymentPageLink} before they are paid into your bank account.
          </Trans>
        </Text>
      </Box>
    </Dialog>
  );
};

export default ExtendDiscountDialog;
