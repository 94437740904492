import { useActivationDiscount } from "./useActivationDiscount";

import { useOrganisation } from "src/libraries/organisation/hooks";
import { useOptimizelyVariation } from "src/technical-integrations/optimizely/useOptimizelyVariation";
import { OptimizelyFlag } from "src/technical-integrations/optimizely/constants";

const MAX_ORGANISATION_AGE_ELIGIBLE_FOR_DISCOUNT_EXTENSION = 30;

interface UseActivationDiscountCanExtend {
  canExtendDiscount: boolean;
}

export function useActivationDiscountCanExtend(): UseActivationDiscountCanExtend {
  const { isVariationOn: isRewardsEnabled } = useOptimizelyVariation({
    flag: OptimizelyFlag.TURBO_GROWTH_REWARDS_AND_BOOSTS,
  });

  const { firstPayoutDiscount, remainingDays = 0 } = useActivationDiscount();
  const organisation = useOrganisation();

  const organisationCreatedAtTimestamp = organisation?.created_at
    ? Math.floor(new Date(organisation?.created_at).getTime())
    : Date.now();
  const organisationCreationDays = Math.ceil(
    (Date.now() - organisationCreatedAtTimestamp) / 86400000
  ); // ms in one day

  const canExtendDiscount =
    (!firstPayoutDiscount || isRewardsEnabled) &&
    organisationCreationDays <=
      MAX_ORGANISATION_AGE_ELIGIBLE_FOR_DISCOUNT_EXTENSION &&
    remainingDays > 0;

  return { canExtendDiscount };
}
