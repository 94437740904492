import {
  CORE_GEO_PRICING,
  REST_OF_WORLD_PRICING,
  NON_CORE_EURO_GEO_PRICING,
} from "./addOnPricingGeoData";

import { CountryCodeSupported, NonCoreEuroGeos } from "src/common/country";

export function retrieveAddOnPricing(geo: CountryCodeSupported) {
  if (NonCoreEuroGeos.includes(geo)) {
    return NON_CORE_EURO_GEO_PRICING;
  }

  const geo_pricing = CORE_GEO_PRICING[geo];
  return geo_pricing || REST_OF_WORLD_PRICING;
}
