import { useState } from "react";
import {
  AlignItems,
  Box,
  ButtonGutter,
  ButtonSize,
  ButtonVariant,
  Color,
  ColorPreset,
  Dialog,
  FontWeight,
  Glyph,
  IconButton,
  Interpose,
  P,
  PlainButton,
  PlainLink,
  Separator,
  Space,
  Text,
  TextAlign,
  TypePreset,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";

import { useRewardsList } from "./useRewardsList";
import { RewardListItem } from "./RewardListItem";

import { ToTranslate } from "src/components/i18n";
import { useActivationDiscount } from "src/components/routes/Setup/common/components/ActivationDiscount/useActivationDiscount";
import { RewardsCostModal } from "src/components/routes/Intro/rewards/RewardsCostModal";
import { renderCapAmount } from "src/components/routes/Setup/common/helpers/activationDiscount";
import { usePrimaryCreditor } from "src/libraries/creditor/hooks";
import { CountryCodes } from "src/common/country";

interface RewardsModalProps {
  open: boolean;
  onClose: () => void;
}

export const RewardsModal: React.FC<RewardsModalProps> = ({
  open,
  onClose,
}) => {
  const { remainingDays } = useActivationDiscount();
  const { rewardsList } = useRewardsList();
  const [openCostModal, setOpenCostModal] = useState(false);

  const { i18n } = useLingui();
  const creditor = usePrimaryCreditor();

  if (!creditor?.geo) return null;

  const capAmount = renderCapAmount(i18n, creditor.geo as CountryCodes);

  return (
    <Box>
      <Dialog
        as="div"
        open={open}
        closeAction={{
          label: <Trans id="Close">Close</Trans>,
          onClose: () => {
            if (!openCostModal) onClose();
          },
        }}
        bodyGutterV={0}
        header={{
          content: (
            <Box>
              <Box spaceAbove={2}>
                <Box
                  bg={Color.Ultraviolet_50}
                  gutterH={1}
                  gutterV={0.25}
                  borderRadius={0.25}
                  width="154px"
                >
                  <P
                    size={2}
                    weight={FontWeight.SemiBold}
                    textAlign={TextAlign.Center}
                  >
                    <ToTranslate>Limited time offer</ToTranslate>
                  </P>
                </Box>
                <Space v={1} />
                <Text preset={TypePreset.Heading_04}>
                  <ToTranslate>Unlock extra days without fees!</ToTranslate>
                </Text>
              </Box>
            </Box>
          ),
          separator: false,
          gutterV: 2,
        }}
      >
        <P
          preset={TypePreset.Body_01}
          color={ColorPreset.TextOnLight_02}
          spaceBelow={2}
        >
          <ToTranslate>
            You can earn up to 120 days without fees by setting up GoCardless
            and using it to get paid! Complete the actions below in your first
            30 days to extend your no-fee period.
          </ToTranslate>
        </P>
        <Box
          bg={Color.Ultraviolet_700}
          gutterV={0.75}
          spaceBelow={1}
          borderRadius={0.5}
        >
          <P
            textAlign={TextAlign.Center}
            preset={TypePreset.Body_01}
            color={Color.White}
            weight={FontWeight.SemiBold}
          >
            <ToTranslate>
              You have {remainingDays} days left with no fees
            </ToTranslate>
          </P>
        </Box>
        <Box
          borderWidth={1}
          borderRadius={1}
          borderColor={ColorPreset.BorderOnLight_03}
          gutterV={1}
        >
          <Interpose
            node={
              <Box spaceAbove={1} spaceBelow={1}>
                <Separator color={ColorPreset.BorderOnLight_03} />
              </Box>
            }
          >
            {rewardsList.map((reward, index) => (
              <RewardListItem
                key={index}
                isUnlocked={reward.isUnlocked}
                title={reward.title}
                rewardDescription={reward.description}
              />
            ))}
          </Interpose>
        </Box>
        <P
          preset={TypePreset.Body_01}
          color={ColorPreset.TextOnLight_02}
          spaceAbove={2}
        >
          <ToTranslate>
            Transaction fees will be free for this period up to a total volume
            of {capAmount}.
          </ToTranslate>{" "}
          <PlainLink
            href="https://gocardless.com/g/no-fees-terms-unlock-discounts/"
            target="_blank"
            decoration="underline"
          >
            <ToTranslate>Terms and conditions apply.</ToTranslate>
          </PlainLink>
        </P>
        <Box
          layout="flex"
          spaceAbove={2}
          spaceBelow={2}
          alignItems={AlignItems.Center}
        >
          <IconButton
            label={
              <ToTranslate>
                How much will it cost after the no-fee period?
              </ToTranslate>
            }
            icon={Glyph.InfoSmall}
            variant={ButtonVariant.SecondaryOnLight}
            size={{ gutters: ButtonGutter.Sm, base: ButtonSize.Sm }}
            onClick={() => setOpenCostModal(true)}
          />

          <Box spaceBefore={0.5}>
            <PlainButton onClick={() => setOpenCostModal(true)}>
              <Text preset={TypePreset.Body_01} weight={FontWeight.SemiBold}>
                <ToTranslate>
                  How much will it cost after the no-fee period?
                </ToTranslate>
              </Text>
            </PlainButton>
          </Box>
        </Box>
      </Dialog>
      <RewardsCostModal
        open={openCostModal}
        onClose={() => setOpenCostModal(false)}
      />
    </Box>
  );
};
